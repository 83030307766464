
* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

body {
  overflow-x: hidden;
  margin: 0;
}

section {
  position: relative;
  z-index: 3;
}

button {
  text-transform: capitalize !important;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;

}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #f7f7f7;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.grecaptcha-badge { 
  visibility: hidden;
}