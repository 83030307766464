#menu-categories {
  color: red;
  z-index: 90;
}

#menu-categories div[role="document"] {
  max-width: 401px;
  overflow-x: scroll;
  z-index: 90;
}

#menu-categories div[role="document"] ul li {
  overflow: initial;
}

.input-custom-location {
  margin: 0 !important;
}

.input-custom-location > div:before{
  content: '';
  border-bottom: 0;
}