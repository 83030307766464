.arrow_box {
  position: relative;
  background-color: #E38D03;
  border: 2px solid #E38D03;
  border-radius:4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.chart-bar .apexcharts-tooltip {
  color: #fff;
  transform: translateX(0) translateY(-15px);
  overflow: visible !important;
  white-space: normal !important;
}

.chart-bar .apexcharts-tooltip span {
  padding: 2px 10px;
  display: inline-block;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 20px;
}