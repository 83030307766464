.dialog {
  position: relative;
  background: #fff;
  color: #000;
  border: 1px solid #e38d03;
  font-family: Helvetica;
  font-size: 14px;
  border-radius: 4px;
  padding: 16px;
}
.dialog .item-value {
  font-weight: 600;
  line-height: 1.8;
}
